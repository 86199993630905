import React, { useEffect, useState, useContext } from "react"
import { isEmpty } from "lodash"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  getCookie,
  getFormattedCart,
  getOgImage,
  isUserLoggedIn,
} from "../../utils/functions"
import Checkout from "../../components/checkout/checkout"
import GET_SHIPPING_Payments from "../../queries/get-shippingPayments"
import { useLazyQuery } from "@apollo/client"
import { AppContext } from "../../components/context/AppContext"
import GET_CART from "../../queries/get-cart"
import { Helmet } from "react-helmet"
import Button from "../../components/button/Button"
import GET_PAYU_DATA from "../../queries/get-payUdata"
import { WPViewContent } from "../../utils/WPpixel"

const CheckoutPage = props => {
  const {
    pageContext: { title, seo, uri, acfCheckout },
  } = props

  useState(() => {
    WPViewContent("View")
  }, [])

  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2)
  const [orderPath, setOrderPath] = useState([])
  const [country, setCountry] = useState(lang.toUpperCase())
  const [passCountry, setPassCountry] = useState(lang.toUpperCase())
  const [loading, setLoading] = useState(false)
  const [shippingMethods, setShippingMethods] = useState([])
  const [paymentsMethods, setPaymentMethods] = useState([])
  const [shippingMethodsMessage, setShippingMethodsMessage] = useState([])
  const [payUData, setPayUData] = useState([])
  const [paymentID, setPaymentID] = useState("")
  const [tempShippingID, setTempShippingID] = useState("")
  const [goPL, setGoPL] = useState([])
  const { messageEmptyCart, buttonBackToProducts } = acfCheckout
  // const { tmpOrderData, setTmpOrderData } = useState({})
  const auth = isUserLoggedIn()
  const [withPickup, toogleWithPickup] = useState(false)
  const [payment, tooglePayment] = useState(false)

  const { cart, setShippingID, shippingID, setCart, setOrderData } =
    useContext(AppContext)

  const [getCheckoutShipping, { data: dataShipping }] = useLazyQuery(
    GET_SHIPPING_Payments,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: () => {
        setShippingMethods(dataShipping?.shippingZone?.node)
        setShippingMethodsMessage(dataShipping?.shippingZone?.message)

        setLoading(false)
        setPaymentMethods(dataShipping?.paymentGateways?.nodes)
        setPaymentID(dataShipping?.paymentGateways?.nodes[0]?.id)

        // if (!shippingID) {
        //   setTempShippingID(dataShipping.shippingZone.node[0]["id_shipping"])
        //   setShippingID(dataShipping.shippingZone.node[0]["id_shipping"])
        // }

        if (country !== passCountry) {
          setTempShippingID(dataShipping.shippingZone.node[0]["id_shipping"])
          setShippingID(dataShipping.shippingZone.node[0]["id_shipping"])
        }
      },
    }
  )

  const [getPayUData, { data: dataPayU }] = useLazyQuery(GET_PAYU_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: () => {
      setPayUData(dataPayU)
    },
  })

  // const setOrderDataWrapper = (data) => {
  //   setTmpOrderData(data)
  //   setOrderData(data)
  // }

  const [getCart, { data: dataGetCart }] = useLazyQuery(GET_CART, {
    variables: {
      country: country,
      shippingID: shippingID,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: () => {
      // console.warn( 'completed GET_CART' );

      // Update cart in the localStorage.
      // console.log(dataGetCart);

      shippingMethods.forEach(element => {
        if (element.id_shipping === shippingID) {
          if (element.withPickup === "true") {
            toogleWithPickup(true)
          } else {
            toogleWithPickup(false)
          }
        }
      })
      if (dataGetCart) {
        const updatedCart = getFormattedCart(dataGetCart)
        // console.log(updatedCart)
        if (updatedCart) {
          localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))

          tooglePayment(+updatedCart.totalPriceWithShippingAndCouponFloat === 0)

          setCart(updatedCart)

          setTimeout(() => {
            if (+dataGetCart?.cart?.subscriptionTotalWithoutCoupnFloat) {
              getCheckoutShipping({
                variables: {
                  country: country,
                  total: +dataGetCart?.cart?.subscriptionTotalWithoutCoupnFloat,
                },
              })
            } else {
              getCheckoutShipping({
                variables: {
                  country: country,
                  total: +dataGetCart?.cart?.totalWithoutCoupnFloat,
                },
              })
            }
          }, 300)
        }
      }
    },
  })

  const shippingMethodsChange = (e, newCountry) => {
    setLoading(true)
    setPassCountry(country)

    setCountry(newCountry)

    if (e) {
      setTempShippingID(e.target.value)
      setShippingID(e.target.value)
    } else {
      setTempShippingID(shippingID)
      setShippingID(shippingID)
    }
  }
  const handleChangePayment = e => {
    setPaymentID(e.target.value)
  }

  useEffect(() => {
    if (lang === "pl") {
      setOrderPath(props.pageContext.orderPathPL)
    }
    if (lang === "en") {
      setOrderPath(props.pageContext.orderPathEN)
    }
    if (lang === "de") {
      setOrderPath(props.pageContext.orderPathDE)
    }
    if (lang === "fr") {
      setOrderPath(props.pageContext.orderPathFR)
    }

    setLoading(true)

    getCheckoutShipping({
      variables: {
        country: country,
        total: cart?.totalProductsWithoutCoupnFloat,
      },
    })

    // setGoPL
    if (!isEmpty(cart)) {
      let tmpCartItem = []
      let gtmCartItem = []
      cart.products.forEach(element => {
        tmpCartItem.push({
          identifier: element.productId,
          quantity: element.qty,
        })

        gtmCartItem.push({
          id: element.productId,
          item_id: element.productId,
          sku: element.productId,
          name: element.name,
          item_name: element.name,
          item_brand: "",
          item_category: element.category,
          category: element.category,
          price: element.price,
          stocklevel: null,
          quantity: element.qty,
        })
      })
      setGoPL(tmpCartItem)

      // console.log(tmpCartItem);

      if (typeof window !== "undefined") {
        if (window.google_tag_manager !== undefined) {
          var gtm = window.google_tag_manager[`${process.env.googleTagManager}`]
          gtm.dataLayer.reset()
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "begin_checkout",
          ecommerce: {
            value: cart.totalPriceWithShippingAndCouponFloat,
            currencyCode: "PLN",
            currency: "PLN",
            items: gtmCartItem,
            checkout: {
              products: gtmCartItem,
            },
          },
        })
      }
    }
  }, [])

  useEffect(() => {
    setTempShippingID(shippingID)
    setPassCountry(country)

    getCart({
      variables: {
        country: lang.toUpperCase(),
        shippingID: shippingID,
      },
    })

    if (cart?.subscription) {
      getPayUData({
        variables: {
          country: country,
          shippingID: shippingID,
        },
      })
    }
  }, [shippingID])

  useEffect(() => {
    // setTempShippingID(shippingID)
    // setPassCountry(country)

    getCart({
      variables: {
        country: lang.toUpperCase(),
        shippingID: shippingID,
      },
    })

    if (cart?.subscription) {
      getPayUData({
        variables: {
          country: country,
          shippingID: shippingID,
        },
      })
    }
  }, [country])

  return (
    <Layout lang={lang} page="checkout" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <Helmet>
            <script src="https://geowidget.easypack24.net/js/sdk-for-javascript.js"></script>
            {/* <script
              type="text/javascript"
              src="//pudofinder.dpd.com.pl/source/dpd_widget.js?key=a9b08eb6c1b218b975529f9cea0e1364"
            ></script> */}
            <link
              rel="stylesheet"
              href="https://geowidget.easypack24.net/css/easypack.css"
            />
            {/* <script type="text/javascript">
              {`
              function pointSelected(pointID){
                const inputPointWrapper = document.getElementById('point-wrapper'); 
                inputPointWrapper.value = pointID;
              }
              `}
            </script> */}
          </Helmet>
          {getCookie("gatsby-gdpr") && cart ? (
            <Helmet>
              <script
                type="text/javascript"
                ata-rh="true"
                data-react-helmet="true"
                src={`https://t.goadservices.com/engine/${
                  process.env.goPl
                }?id=${getCookie("__goadservices")}`}
              ></script>
              <script
                type="text/javascript"
                ata-rh="true"
                data-react-helmet="true"
              >
                {`
                  var goadservicesq = goadservicesq || [];
                  try {
                    goadservicesq.push(
                        [
                            "_BASKET",
                            ${goPL.map(item => {
                              return `
                              {
                                identifier: '${item.identifier}',
                                quantity: '${item.quantity}',
                              },
                              `
                            })}
                            {}
                        ]
                    );
                  } catch (e) {
                      // console.log(e)
                  }
                `}
              </script>
            </Helmet>
          ) : (
            ""
          )}
          <SEO
            title={title}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"checkout"}
            // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
            openGraphImage={getOgImage(seo)}
          />
          {cart ? (
            <Checkout
              loadingShipping={loading}
              orderPath={orderPath}
              checkout={acfCheckout}
              authData={auth}
              shippingID={shippingID}
              shippingMethods={shippingMethods}
              shippingMethodsMessage={shippingMethodsMessage}
              shippingMethodsChange={shippingMethodsChange}
              cart={cart}
              tempShippingID={tempShippingID}
              paymentsMethods={paymentsMethods}
              paymentID={paymentID}
              handleChangePayment={handleChangePayment}
              setOrderData={setOrderData}
              payUData={payUData}
              withPickup={withPickup}
              payment={payment}
              toogleWithPickup={toogleWithPickup}
              // orderData={tmpOrderData}
            />
          ) : (
            <div className="page">
              <div className="container">
                <div className="content">
                  <div dangerouslySetInnerHTML={{ __html: messageEmptyCart }} />
                  <Button
                    title={buttonBackToProducts.title}
                    url={buttonBackToProducts.url}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}
export default CheckoutPage
