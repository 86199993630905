import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import isEmpty from "validator/es/lib/isEmpty";
import './style.scss'

const CheckoutProducts = ({ products }) => {
  return (
    <div className="checkoutProducts">
      {products.map(productsItems => (
        <div className="checkoutProducts__item" key={productsItems.cartKey}>
          <div className="checkoutProducts__img">
            <LazyLoadImage
              alt={productsItems.image.title}
              src={!isEmpty(productsItems.image.sourceUrl) ? productsItems.image.sourceUrl : ''} // use normal <img> attributes as props
              effect="blur"
            />
          </div>
          <div className="checkoutProducts__name">
            {productsItems.name} x {productsItems.qty}
          </div>
          <div className="checkoutProducts__price">
            <span dangerouslySetInnerHTML={{ __html: productsItems.totalPrice }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CheckoutProducts;
