import { gql } from "@apollo/client";

const GET_PAYU_DATA = gql`
query GET_PAYU_DATA($shippingID: String) {
    subscriptionData(shippingID: $shippingID){
        shop_name
        total_amount
        currency_code
        customer_language
        store_card
        recurring_payment
        customer_email
        sig
        widget_url
        merchant_pos_id
    }
}
`;

export default GET_PAYU_DATA;