import React from "react"

const PaymentsMethods = ({
  paymentsMethods,
  handleChangePayment,
  paymentLabel,
  paymentID,
  withPickup,
  isPay
}) => {

  return (
    <>
      {!withPickup && !isPay ? (
        <div className="checkout__order__payment">
          <p className="checkout__order__heading">{paymentLabel}</p>
          {paymentsMethods.length
            ? paymentsMethods.map(payment => (
                <div className="form__radio" key={payment.id}>
                  <label htmlFor={payment.id}>
                    <input
                      type="radio"
                      checked={paymentID === payment.id ? "checked" : ""}
                      name="payment"
                      value={payment.id}
                      id={payment.id}
                      onChange={e => {
                        handleChangePayment(e)
                      }}
                    />
                    <span className="form__radio__label">
                      <span className="form__radio__label__heading">
                        {payment.title}
                      </span>
                      {payment.description ? (
                        <span className="form__radio__label__description">
                          {payment.description}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </label>
                </div>
              ))
            : ""}
        </div>
      ) : (
        ""
      )}
    </>
  )
}
export default PaymentsMethods
