import { gql } from "@apollo/client";

const CHECKOUT_MUTATION = gql`
mutation CHECKOUT_MUTATION( $input: CheckoutInput! ) {
  checkout(input: $input) {
    clientMutationId
    order {
      id
      orderKey
      refunds {
        nodes {
          amount
        }
      }
      downloadableItems {
        nodes {
          name
          url
        }
      }
      status
      orderNumber
      paymentMethod
      totalTax(format: RAW)
      total(format: RAW)
      
      datePaid
      paymentMethodTitle
      shippingLines {
        nodes {
          methodTitle
          total
          totalTax
        }
      }
    }
    result
    redirect
  }
}
`;

export default CHECKOUT_MUTATION;
