import React from "react"
import "./style.scss"

const CheckoutCoupons = ({ coupons }) => {
  return (
    <div className="checkoutCoupons">
      {coupons.map(couponsItems => (
        <div className="checkoutCoupons__item" key={couponsItems.cartKey}>
          <div className="checkoutCoupons__name">
            {couponsItems.name}
           
          </div>
          <div className="checkoutCoupons__price">
            <span
              dangerouslySetInnerHTML={{
                __html: couponsItems.discountAmountType,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default CheckoutCoupons
