import { gql } from "@apollo/client";

const GET_SHIPPING_Payments = gql`
query GET_SHIPPING_Payments($country: String, $total: Float) {
  shippingZone(country: $country, total: $total) {
    node {
      title
      description
      free_shiping
      shiping_price
      shiping_price_float
      id_shipping
      free_shipping_label
      integration
      withPickup
      freeCoupon
    }
    message
  }
  paymentGateways {
    nodes {
      title
      id
      description
    }
  }
}`;

export default GET_SHIPPING_Payments;